import { NotificationType } from "common/Type/notificationType";
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import { saveToLocalStorage } from "./Admin/Chat/components/RoomList";
import { AuthContext } from "./AuthProvider";

//-------------------------------------

interface MessageAuth {
  connWebsoket: WebSocket | null;
  notiRoom: number[] | null;
  newNotiNumber: number | null;
  dataNotiSns: NotificationType[] | null;
  updateNotiRoom: (e: number[]) => void;
  updateNewNotiRoom: (e: number[]) => void;
  updateNewNotiNumber: (e: number) => void;
  updateDataNotiSns: (e: NotificationType[]) => void;
}

const defaultValue: MessageAuth = {
  connWebsoket: null,
  notiRoom: null,
  updateNotiRoom: (e) => {},
  newNotiNumber: null,
  updateNewNotiRoom: (e) => {},
  updateNewNotiNumber: (e) => {},
  dataNotiSns: null,
  updateDataNotiSns: (e) => {},
};

export const MessageContext = createContext<MessageAuth>(defaultValue);

const MessageProvider = React.memo((props) => {
  const { admin } = useContext(AuthContext);

  const [connWebsoket, setConnection] = useState<WebSocket | null>(null);
  const [notiRoom, setNotiRoom] = useState<number[] | null>(null);

  const [dataNotiSns, setDataNotiSns] = useState<NotificationType[] | null>(
    null
  );
  const [newNotiNumber, setNewNotiNumber] = useState<number | null>(null);

  const updateDataNotiSns = (data: NotificationType[]) => {
    if (data) {
      setDataNotiSns([...data]);
    } else setDataNotiSns(null);
  };

  const updateNotiRoom = (data: number[]) => {
    setNotiRoom((prev) => Array.from(new Set([...(prev || []), ...data])));
  };
  const updateNewNotiNumber = (data: number) => {
    setNewNotiNumber(data);
  };

  const updateNewNotiRoom = (data: number[]) => {
    setNotiRoom(data);
  };
  if (notiRoom) saveToLocalStorage("array", notiRoom);
  const adminToken =
    admin &&
    admin.country_id &&
    admin.wallet_address &&
    localStorage.getItem("admin_token");

  const connect = useCallback(() => {
    if (adminToken) {
      const connectWs = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET}?access_token=${adminToken}`
      );
      connectWs.onopen = () => {
        connectWs.send(
          JSON.stringify({
            command: "register",
          })
        );
        connectWs.send(
          JSON.stringify({
            command: "unread",
          })
        );
      };
      // connectWs.onmessage = (e: any) => {
      //   const dataMessage = JSON.parse(e.data);
      //   if (
      //     dataMessage.command === "unread" &&
      //     dataMessage.data.rooms.length !== 0
      //   ) {
      //     updateNotiMessage(true);
      //     const listPoIdUnRead = dataMessage.data.rooms.map((item: any) => {
      //       return item.po_id;
      //     });
      //     if (listPoIdUnRead)
      //       saveToLocalStorage("listPoIdUnRead", listPoIdUnRead);
      //   }
      //   if (dataMessage.command === "message") {
      //     updateNotiMessage(true);
      //     updateDataFirebase(dataMessage.data);
      //   }
      // };
      setConnection(connectWs);
    } else {
      setConnection(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminToken]);

  useEffect(() => {
    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: MessageAuth = {
    connWebsoket,
    notiRoom,
    updateNotiRoom,
    newNotiNumber,
    updateNewNotiNumber,
    updateNewNotiRoom,
    dataNotiSns,
    updateDataNotiSns,
  };

  return <MessageContext.Provider value={value as MessageAuth} {...props} />;
});

export default MessageProvider;
