import React from "react";
import { Stack } from "@mui/material";
import { Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import bottomIcon from "../../assets/startup_market/bottom-icon.png";
import telegramIcon from "../../assets/startup_market/telegram-icon.png";
import xIcon from "../../assets/startup_market/x-logo.png";

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <Stack className={classes.root}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <img
              src={bottomIcon}
              height={"auto"}
              width={32}
              style={{
                marginRight: theme.spacing(2.5),
              }}
              alt=""
            />
            <Typography
              style={{
                fontSize: "28px",
                lineHeight: "32px",
                fontWeight: 800,
                color: "white",
                fontFamily: "Alef",
              }}
            >
              Startup Market
            </Typography>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: "20px",
                lineHeight: "29px",
                color: "white",
                fontFamily: "Almarai",
                fontWeight: 400,
                width: "60%",
                margin: "0px",
                whiteSpace: "pre-line",
              }}
            >
              Startup Market is a platform that connects entrepreneurs, investors, and experts, powered by Superchain—a composable blockchain for swift, transparent deals. It fosters collaboration, drives growth, and opens doors to new opportunities worldwide.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "80px",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  color: "#CECECE",
                  fontFamily: "Almarai",
                  margin: "0px",
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#CECECE",
                  fontFamily: "Almarai",
                  margin: "0px",
                  cursor: "pointer",
                }}
              >
                Terms of Use
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              margin: "40px 0px 20px 0px",
            }}
          >
            <img
              src={telegramIcon}
              height={"auto"}
              width={37}
              style={{
                marginRight: theme.spacing(2.5),
                cursor: "pointer",
              }}
              alt=""
            />
            <img
              src={xIcon}
              height={"auto"}
              width={28}
              style={{
                marginRight: theme.spacing(2.5),
                cursor: "pointer",
              }}
              alt=""
            />
          </div>
        </Grid>
      </Stack>
      <Typography
        style={{
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "22px",
          color: "white",
          textAlign: "left",
          background: "#000000",
          fontFamily: "Almarai",
          padding: "16px calc(5% + 24px) 32px",
          borderTop: "1px solid #5F5F5F",
        }}
      >
        © 2024 Startup Market. All rights reserved.
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: "32px calc(5% + 24px)",
    minHeight: "314px",
    backgroundColor: "#000000",
  },
  labelText: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
    color: "white",
    marginLeft: theme.spacing(1),
    fontFamily: "Lato",
  },
  valueText: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 700,
    color: "white",
    marginLeft: theme.spacing(5),
    fontFamily: "Lato",
  },
}));
