import { lazy } from "react";

const Index = lazy(() => import("../containers/Admin/Home/Home"));
const Login = lazy(() => import("../containers/Auth/Login"));
const NotiRegister = lazy(
  () => import("../containers/Admin/NotiRegister/NotiRegister")
);

const Project = lazy(() => import("../containers/Admin/Project/Project"));
const ProjectDetail = lazy(
  () => import("../containers/Admin/ProjectDetail/ProjectDetail")
);
const MyPerformance = lazy(
  () => import("../containers/Admin/MyPerformance/MyPerformance")
);

const IDO = lazy(() => import("../containers/Admin/IDO/IDO"));
const IDO1 = lazy(() => import("../containers/Admin/IDO/IDO1"));

export const adminPrefix = "";
export const adminApi = "/admin";

export function AdminRoutes() {
  const adminRoutes = [
    {
      path: "/",
      component: Index,
      protected: true,
      perm: "",
      name: "Dashbroad",
    },
    {
      path: "/login",
      component: Login,
      protected: true,
      perm: "",
      name: "Login",
    },
    {
      path: "/notificationRegister",
      component: NotiRegister,
      protected: true,
      perm: "",
      name: "Notification Register",
    },
    {
      path: "/project",
      component: Project,
      protected: true,
      perm: "admin",
      name: "Project",
    },
    {
      path: "/project-tokensale/:id",
      component: ProjectDetail,
      protected: true,
      perm: "admin",
      name: "Project detail",
    },
    {
      path: "/myPerformance",
      component: MyPerformance,
      protected: true,
      perm: "admin",
      name: "Project",
    },
    {
      path: "/ido",
      component: IDO,
      protected: true,
      perm: "",
      name: "IDO",
    },
    {
      path: "/ido-1",
      component: IDO1,
      protected: true,
      perm: "",
      name: "IDO",
    },
  ];

  return { adminRoutes };
}
