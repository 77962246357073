import { Box, Stack } from "@mui/material";
import { RoomData } from "common/Type/messageType";
import { AuthContext } from "containers/AuthProvider";
import { MessageContext } from "containers/MessageProvider";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserInfo from "./UserInfo";
interface Props {
  data: RoomData[];
}

// Hàm để lưu dữ liệu vào localStorage
export const saveToLocalStorage = (key: string, data: number[] | boolean) => {
  localStorage.setItem(key, JSON.stringify(data));
};

// Hàm để đọc dữ liệu từ localStorage
export const getFromLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

const RoomList: React.FC<Props> = ({ data }) => {
  const history = useHistory();
  const { conversationSelected, updateConversation } = useContext(AuthContext);

  const { connWebsoket, updateNotiRoom, updateNewNotiRoom } = useContext(
    MessageContext
  );

  const storedArray = getFromLocalStorage("array");
  const listPoIdUnRead = getFromLocalStorage("listPoIdUnRead");

  useEffect(() => {
    if (listPoIdUnRead && listPoIdUnRead.length !== 0 && connWebsoket) {
      updateNotiRoom(listPoIdUnRead);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connWebsoket]);

  useEffect(() => {
    if (history.location.search && connWebsoket) {
      const search = history.location.search.split("=");
      const newListPoIdUnRead =
        listPoIdUnRead &&
        listPoIdUnRead.length !== 0 &&
        listPoIdUnRead.filter((e: number) => e !== +search[1]);
      if (listPoIdUnRead && listPoIdUnRead.length !== 0) {
        saveToLocalStorage("listPoIdUnRead", newListPoIdUnRead);
      }
      if (+conversationSelected === +search[1]) {
        connWebsoket.send(
          JSON.stringify({
            command: "read",
            data: {
              po_id: +search[1],
            },
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search, connWebsoket, conversationSelected]);
  return (
    <Stack>
      {data?.map((item: RoomData, index) => (
        <div
          key={index}
          onClick={() => {
            updateConversation(item.po_id);
            history.push(`/message?room_id=${item.po_id}`);

            if (
              storedArray &&
              storedArray.filter((e: number) => e === item.po_id).length !== 0
            ) {
              let room_id = item.po_id;
              const new_arr = storedArray.filter(
                (item: number) => item !== room_id
              );
              updateNewNotiRoom(new_arr);
            }
          }}
          style={{
            cursor: "pointer",
            padding: "8px 8px 8px 16px ",
            backgroundColor:
              +conversationSelected === item.po_id ? "#56CCF2" : "unset",
          }}
        >
          <Box paddingRight="16px" position="relative">
            <UserInfo name={item.project_owner.email} SizeText="body2" />

            {storedArray &&
              storedArray.filter((e: number) => e === item.po_id).length !==
                0 && (
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    position: "absolute",
                    top: "5px",
                    right: "0px",
                  }}
                ></Box>
              )}
          </Box>
        </div>
      ))}
    </Stack>
  );
};

export default RoomList;
