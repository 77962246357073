import React from "react";
import "./App.css";
import AuthProvider from "./containers/AuthProvider";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import MainRouter from "./routes/MainRouter";
import "./scrollbar.css";
import withFirebaseAuth from "react-with-firebase-auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import fireApp from "firebaseMess";
import MessageProvider from "containers/MessageProvider";
import { CHAIN_SETTINGS } from "blockchain/chainSettings";
//import "swiper/css/bundle";
const firebaseAppAuth = fireApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0052cc",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        },
      },
    },
  },
});

const {
  provider,
  webSocketProvider,
  chains,
} = configureChains(CHAIN_SETTINGS(), [publicProvider()]);

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "wagmi",
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true,
      },
    }),
  ],
});

function App() {
  return (
    <WagmiConfig client={client}>
      <AuthProvider>
        <MessageProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MainRouter />
          </ThemeProvider>
        </MessageProvider>
      </AuthProvider>
    </WagmiConfig>
  );
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
