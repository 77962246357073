import {
  Button,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Box,
  Dialog,
  Stack,
  Typography as TypographyMui5,
  IconButton as IconButtonMui5,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAPI } from "api/api";
import { AuthContext } from "containers/AuthProvider";
import { AutocompleteContainer } from "components/AutocompleteContainer";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import { UploadFile } from "components/upload/UploadFile";
interface Props {
  handleCloseDialog: () => void;
  open: boolean;
}

type Data = {
  name: string;
  google_id: string;
  telegram_id: string;
  discord_id: string;
  email: string;
  country: any;
  wallet_address: string;
  facebook_id: string;
  facebook_channel: string;
  facebook_start_price: string;
  twitter_id: string;
  twitter_channel: string;
  twitter_start_price: string;
  youtube_channel: string;
  youtube_start_price: string;
  tiktok_channel: string;
  tiktok_start_price: string;
  my_referer_code: string;
};

type Field =
  | "name"
  | "google_id"
  | "telegram_id"
  | "discord_id"
  | "email"
  | "country"
  | "wallet_address"
  | "facebook_id"
  | "facebook_channel"
  | "facebook_start_price"
  | "twitter_id"
  | "twitter_channel"
  | "twitter_start_price"
  | "youtube_channel"
  | "youtube_start_price"
  | "tiktok_channel"
  | "tiktok_start_price"
  | "my_referer_code";

type SNSType = {
  sns_name: string;
  url: string;
  error?: boolean;
};

type dataSns = {
  sns_name: string;
  url: string;
};

export const ChangeInfo: React.FC<Props> = ({ handleCloseDialog, open }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const api = useAPI();
  const dataAuth = useContext(AuthContext);
  const [sns, setSns] = useState<SNSType[]>([]);
  const [file, setFile] = useState<any>();
  const [data, setData] = useState<Data>({
    name: "",
    google_id: "",
    telegram_id: "",
    discord_id: "",
    email: "",
    country: {},
    wallet_address: "",
    facebook_id: "",
    facebook_channel: "",
    facebook_start_price: "",
    twitter_id: "",
    twitter_channel: "",
    twitter_start_price: "",
    youtube_channel: "",
    youtube_start_price: "",
    tiktok_channel: "",
    tiktok_start_price: "",
    my_referer_code: "",
  });
  useEffect(() => {
    if (dataAuth.admin) {
      const value: Data = {
        name: dataAuth.admin?.name,
        google_id: dataAuth.admin?.google_id,
        telegram_id: dataAuth.admin?.telegram_id,
        discord_id: dataAuth.admin?.discord_id,
        email: dataAuth.admin?.email,
        country: dataAuth.admin?.country,
        wallet_address: dataAuth.admin?.wallet_address,
        facebook_id: dataAuth.admin?.facebook_id,
        facebook_channel:
          dataAuth.admin?.facebook_channel &&
          String(dataAuth.admin?.facebook_channel).slice(25),
        facebook_start_price:
          dataAuth.admin?.facebook_start_price != 0 &&
          dataAuth.admin?.facebook_start_price,
        twitter_id: dataAuth.admin?.twitter_id,
        twitter_channel:
          dataAuth.admin?.twitter_channel &&
          String(dataAuth.admin?.twitter_channel).slice(20),
        twitter_start_price:
          dataAuth.admin?.twitter_start_price != 0 &&
          dataAuth.admin?.twitter_start_price,
        youtube_channel:
          dataAuth.admin?.youtube_channel &&
          String(dataAuth.admin?.youtube_channel).slice(25),
        youtube_start_price:
          dataAuth.admin?.youtube_start_price != 0 &&
          dataAuth.admin?.youtube_start_price,
        tiktok_channel:
          dataAuth.admin?.tiktok_channel &&
          String(dataAuth.admin?.tiktok_channel).slice(24),
        tiktok_start_price:
          dataAuth.admin?.tiktok_start_price != 0 &&
          dataAuth.admin?.tiktok_start_price,
        my_referer_code: dataAuth.admin?.referer_code,
      };
      setData(value);
      setSns(
        dataAuth.admin?.sns?.length > 0
          ? typeof dataAuth.admin?.sns == "string"
            ? JSON.parse(dataAuth.admin?.sns)
            : dataAuth.admin?.sns
          : []
      );
      dataAuth.admin?.avatar_url && setFile(dataAuth.admin?.avatar_url);
    }
  }, [dataAuth.admin]);

  const changeData = (value: string, field: Field) => {
    let tempData: Data = { ...data };
    tempData[field] = value;
    setData(tempData);
  };

  const checkEmptySns = useMemo(() => {
    let check = false;
    check = sns.some((data) => data.url === "" || data.sns_name === "");
    return check;
  }, [sns]);

  const checkNameAndCountry = useMemo(() => {
    let check = false;
    if (!data.name || !data.country?.id) {
      check = true;
    }
    return check;
  }, [data]);

  const formData = new FormData();

  const updateData = async () => {
    if (checkEmptySns === false && checkNameAndCountry === false) {
      const dataSns: dataSns[] = [];
      sns.forEach((data: SNSType) => {
        dataSns.push({
          sns_name: data.sns_name,
          url: data.url,
        });
      });
      data.name && formData.append("name", data.name);
      data.google_id && formData.append("google_id", data.google_id);
      data.facebook_id && formData.append("facebook_id", data.facebook_id);
      data.facebook_channel &&
        formData.append(
          "facebook_channel",
          "https://www.facebook.com/" + data.facebook_channel
        );
      data.facebook_start_price &&
        formData.append(
          "facebook_start_price",
          data.facebook_start_price.replace(/,/g, "").toString()
        );
      data.twitter_id && formData.append("twitter_id", data.twitter_id);
      data.twitter_channel &&
        formData.append(
          "twitter_channel",
          "https://twitter.com/" + data.twitter_channel
        );
      data.twitter_start_price &&
        formData.append(
          "twitter_start_price",
          data.twitter_start_price.replace(/,/g, "").toString()
        );
      data.youtube_channel &&
        formData.append(
          "youtube_channel",
          "https://www.youtube.com/@" + data.youtube_channel
        );
      data.youtube_start_price &&
        formData.append(
          "youtube_start_price",
          data.youtube_start_price.replace(/,/g, "").toString()
        );
      data.tiktok_channel &&
        formData.append(
          "tiktok_channel",
          "https://www.tiktok.com/@" + data.tiktok_channel
        );
      data.tiktok_start_price &&
        formData.append(
          "tiktok_start_price",
          data.tiktok_start_price.replace(/,/g, "").toString()
        );
      data?.discord_id && formData.append("discord_id", data?.discord_id);
      data?.email && formData.append("email", data?.email);
      data?.country && formData.append("country_id", data?.country?.id);
      data?.wallet_address &&
        formData.append("wallet_address", data?.wallet_address);
      dataSns && formData.append("sns", JSON.stringify(dataSns));
      file && formData.append("file", file);
      formData.append("_method", "PUT");
      const res = await api.fetcher("post", "/user/update-me", formData);
      if (res) {
        dataAuth.revalidate();
        handleCloseDialog();
      }
    }
  };

  const dataError: any = useMemo(() => {
    if (api.error) {
      return api.error;
    } else {
      return {};
    }
  }, [api.error]);
  const copyLink = () => {
    toast.success(t("project_detail.copied"), {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const convertStringAddress = (value: string) => {
    return (
      value.slice(0, 8) +
      "........." +
      value.slice(value.length - 7, value.length)
    );
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
      BackdropProps={{
        sx: {
          backgroundColor: "#141414cc",
        },
      }}
      sx={{
        "& .MuiPaper-root": {
          background: "#ffffff",
          margin: "16px 16px",
          maxHeight: "calc(100% - 32px)",
          borderRadius: "5px",
          width: "100%",
        },
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        m={"8px"}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          mt={"16px"}
          position="relative"
          sx={{ maxWidth: "90%" }}
          id="element fix"
        >
          <UploadFile
            files={file}
            setFiles={setFile}
            error=""
            readOnly={false}
            name=""
          />
          <Box sx={{ position: "absolute", top: "-8px", right: "-16px" }}>
            <IconButtonMui5 size="large" onClick={() => handleCloseDialog()}>
              <CloseIcon style={{ color: "black" }} />
            </IconButtonMui5>
          </Box>
          <TypographyMui5 variant="h6" color="black" fontWeight={700}>
            {t("Update_info.basic_information")}
          </TypographyMui5>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width={"100%"}
            mt="16px"
          >
            <TypographyMui5
              variant="body2"
              color="black"
              style={{ maxWidth: "90%" }}
            >
              My referer code:{" "}
              <span
                style={{ fontSize: "18px", height: "20px", fontWeight: "600" }}
              >
                {convertStringAddress(data?.my_referer_code)}
              </span>
            </TypographyMui5>
            <CopyToClipboard text={data && data.my_referer_code}>
              <IconButtonMui5
                size="small"
                sx={{ padding: "0px" }}
                onClick={() => copyLink()}
              >
                <FileCopyIcon
                  style={{
                    color: "#56CCF2",
                    width: "20px",
                    height: "20px",
                    paddingBottom: "3px",
                  }}
                />
              </IconButtonMui5>
            </CopyToClipboard>
          </Stack>
          <Typography className={classes.labelText}>
            {t("home.Your_name")}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            className={classes.textFieldContainer}
            placeholder={t("home.Your_name")}
            value={data?.name}
            onChange={(e) => changeData(e.target.value as string, "name")}
            InputProps={{
              classes: { input: classes.input },
              className: classes.lineColor,
              style: { height: "50px", background: "white" },
            }}
          />
          <Typography className={classes.labelText}>
            {t("header.country")} *
          </Typography>
          <AutocompleteContainer
            label={t("header.country") + "*"}
            value={data?.country}
            endpoint="user/countries"
            queryField="name"
            labelField="name"
            valueField="id"
            handleChange={(e) => {
              changeData(e as string, "country");
            }}
            error={
              checkNameAndCountry === true && !data.country?.id
                ? "Please select your country"
                : ""
            }
          />
          <div
            style={{
              marginBottom:
                checkNameAndCountry === true && !data.country?.id
                  ? "20px"
                  : "0px",
            }}
          ></div>

          <Typography className={classes.labelText}>Email</Typography>
          <TextField
            variant="outlined"
            fullWidth
            className={classes.textFieldContainer}
            placeholder="Email"
            value={data?.email}
            InputProps={{
              classes: { input: classes.input },
              className: classes.lineColor,
              style: { height: "50px", background: "white" },
            }}
            onChange={(e) =>
              dataAuth.admin?.login_by !== 1 &&
              changeData(e.target.value as string, "email")
            }
            error={Boolean(dataError?.email)}
          />
          {dataError?.email && (
            <Typography
              style={{
                color: "#f44336",
                fontSize: "14px",
                fontWeight: 400,
                marginLeft: "10px",
              }}
            >
              Email {t("home.using")}
            </Typography>
          )}

          <TypographyMui5 variant="h6" color="black" fontWeight={700} mt="24px">
            {t("Update_info.social_media")}
          </TypographyMui5>
          <Typography className={classes.labelText}>Youtube</Typography>
          <TypographyMui5 variant="subtitle2" color="#8b8b8b">
            {" "}
            https://www.youtube.com/
            <b style={{ color: "#56CCF2" }}>@name</b>
          </TypographyMui5>
          <TextField
            variant="outlined"
            fullWidth
            className={classes.textFieldContainer}
            placeholder="Youtube Channel Name"
            value={data?.youtube_channel}
            InputProps={{
              classes: { input: classes.input },
              className: classes.lineColor,
              style: { height: "50px", background: "white" },
              startAdornment: (
                <InputAdornment position="start" style={{ color: "black" }}>
                  <Typography style={{ color: "black" }}>@</Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) =>
              changeData(e.target.value as string, "youtube_channel")
            }
            error={Boolean(dataError?.youtube_channel)}
          />
          {dataError?.youtube_channel && (
            <Typography
              style={{
                color: "#f44336",
                fontSize: "14px",
                fontWeight: 400,
                marginLeft: "10px",
              }}
            >
              Youtube Channel {t("home.using")}
            </Typography>
          )}

          <Typography className={classes.labelText}>X</Typography>
          <TypographyMui5 variant="subtitle2" color="#8b8b8b">
            {" "}
            https://www.x.com/
            <b style={{ color: "#56CCF2" }}>name</b>
          </TypographyMui5>
          <TextField
            variant="outlined"
            fullWidth
            className={classes.textFieldContainer}
            placeholder="X Channel Name"
            value={data?.twitter_channel}
            InputProps={{
              classes: { input: classes.input },
              className: classes.lineColor,
              style: { height: "50px", background: "white" },
            }}
            onChange={(e) =>
              changeData(e.target.value as string, "twitter_channel")
            }
            error={Boolean(dataError?.twitter_channel)}
          />
          {dataError?.twitter_channel && (
            <Typography
              style={{
                color: "#f44336",
                fontSize: "14px",
                fontWeight: 400,
                marginLeft: "10px",
              }}
            >
              Twitter Channel {t("home.using")}
            </Typography>
          )}

          <Typography className={classes.labelText}>Tiktok</Typography>
          <TypographyMui5 variant="subtitle2" color="#8b8b8b">
            {" "}
            https://www.tiktok.com/
            <b style={{ color: "#56CCF2" }}>@name</b>
          </TypographyMui5>
          <TextField
            variant="outlined"
            fullWidth
            className={classes.textFieldContainer}
            placeholder="Tiktok Channel Name"
            value={data?.tiktok_channel}
            InputProps={{
              classes: { input: classes.input },
              className: classes.lineColor,
              style: { height: "50px", background: "white" },
              startAdornment: (
                <InputAdornment position="start" style={{ color: "black" }}>
                  <Typography style={{ color: "black" }}>@</Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) =>
              changeData(e.target.value as string, "tiktok_channel")
            }
            error={Boolean(dataError?.tiktok_channel)}
          />
          {dataError?.tiktok_channel && (
            <Typography
              style={{
                color: "#f44336",
                fontSize: "14px",
                fontWeight: 400,
                marginLeft: "10px",
              }}
            >
              Tiktok Channel {t("home.using")}
            </Typography>
          )}

          <Typography className={classes.labelText}>
            {t("Update_info.wallet_address")}
          </Typography>
          <Typography
            className={classes.labelText}
            style={{ marginLeft: "10px", fontSize: "18px" }}
          >
            {data?.wallet_address}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={{
              backgroundColor: "#ffffff1a",
              padding: 0,
              height: "50px",
              marginRight: "24px",
              borderRadius: "12px",
              marginTop: "16px",
              marginBottom: "32px",
            }}
            onClick={() => updateData()}
          >
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 700,
                color: "#000000",
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("header.Change_Information")}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

const useStyle = makeStyles((theme) => ({
  input: {
    "&::placeholder": {
      fontStyle: "italic",
      color: "black",
    },
  },
  lineColor: {
    color: "black",
    borderRadius: "20px",
  },
  labelText: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    color: "black",
    marginBottom: "2px",
    marginTop: "16px",
    wordBreak: "break-all",
  },
  textFieldContainer: {
    backgroundColor: "#0000004d",
    borderRadius: "20px",
    border: "1px solid #bdbdbd4d",
    color: "black",
  },
}));
