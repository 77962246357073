import { FormGroup } from "@mui/material";
import React from "react";
import CheckboxItem from "./CheckboxItem";

export interface RadioProps {
  index: number;
  data: any;
}

const QuestionMultiSelect: React.FC<RadioProps> = ({ data, index }) => {
  return (
    <>
      <FormGroup>
        {data.answer_selection.map((answerItem: any, idx: number) => (
          <CheckboxItem
            key={idx}
            data={data}
            index={index}
            answerItem={answerItem}
          />
        ))}
      </FormGroup>
    </>
  );
};

export default QuestionMultiSelect;
