import React, { useContext, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import startup_market from "../../assets/image/startup_market_logo_header.png";
import { makeStyles } from "@material-ui/core";
import { AppBar, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { AuthContext } from "containers/AuthProvider";
import { useTranslation } from "react-i18next";
import { PersonInfo } from "./PersonInfo";
import { HeaderMobile } from "./HeaderMobile";
import { isMobile } from "common/constants";

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      height: "40px",
    },
    [theme.breakpoints.up("md")]: {
      height: "70px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "70px",
    },
  },
  showHeader: {
    [theme.breakpoints.down("sm")]: {
      display: "none !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  headerMobie: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block !important",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  linkTextActive: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 700,
    color: "#000",
  },
  linkTextUnActive: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
    color: "#00000080",
  },
  activeLink: {
    // backgroundImage: `linear-gradient(to bottom,#ffffff00,#003469)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundBlendMode: "color-dodge",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "14px",
    cursor: "pointer",
    minWidth: "120px",
  },
  noActiveLink: {
    // background: "transparent",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "14px",
    cursor: "pointer",
    minWidth: "120px",
  },
  changeLanguageText: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 700,
    color: "white",
    fontFamily: "Montserrat",
  },
  backgroundDrawer: {
    backgroundColor: "#21243f80",
    minWidth: "240px",
  },
  containerDrawerItem: {
    width: "100%",
    height: "50px",
    paddingLeft: "10px",
  },
  drawerText: {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 900,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
}));

export default function Header(props: any) {
  const { t } = useTranslation();
  const dataAuth = useContext(AuthContext);
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (window.screen.width > 1000) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  const [activeSideBar, setActiveSideBar] = useState<string>("");

  const data = window.location.pathname.split("/");

  useEffect(() => {
    if (data.length > 1) {
      setActiveSideBar(data[1]);
      localStorage.setItem("url", window.location.pathname);
    }
  }, [data, history]);

  const dataSidebar = useMemo(() => {
    return !dataAuth.admin
      ? [
          {
            name: t("header.home"),
            path: "",
            active: "",
            display: true,
          },
          {
            name: t("header.Project"),
            path: "/project",
            active: "project",
            display: dataAuth.admin ? true : false,
          },
          {
            name: t("header.check_status"),
            path: "/myPerformance",
            active: "myPerformance",
            display: dataAuth.admin ? true : false,
          },
        ]
      : dataAuth.admin?.country_id && dataAuth.admin?.wallet_address
      ? [
          {
            name: t("header.home"),
            path: "",
            active: "",
            display: true,
          },
          {
            name: t("header.Project"),
            path: "/project",
            active: "project",
            display: dataAuth.admin ? true : false,
          },
          {
            name: t("header.check_status"),
            path: "/myPerformance",
            active: "myPerformance",
            display: dataAuth.admin ? true : false,
          },
        ]
      : [
          {
            name: t("header.Update_Information"),
            path: "/",
            active: "",
            display: true,
          },
        ];
  }, [dataAuth, t]);
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.open,
        })}
        sx={{
          backgroundColor: "#fff",
          fontFamily: "Montserrat",
          boxShadow: "unset",
        }}
        id="header"
      >
        <Toolbar className={classes.toolbar}>
          {show === true && (
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                padding: "0px 5%",
              }}
              className={classes.showHeader}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100%" }}
              >
                <div
                  style={{
                    marginRight: theme.spacing(8),
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                  }}
                >
                  <img
                    src={startup_market}
                    height={32}
                    width={"auto"}
                    alt=""
                    onClick={() => {
                      history.push("/");
                      (!dataAuth.admin?.country_id ||
                        !dataAuth.admin?.wallet_address) &&
                        dataAuth.clear();
                    }}
                  />
                  <p
                    style={{
                      fontSize: "28px",
                      color: "#00B6F0",
                      fontWeight: 700,
                    }}
                    onClick={() => {
                      history.push("/");
                      (!dataAuth.admin?.country_id ||
                        !dataAuth.admin?.wallet_address) &&
                        dataAuth.clear();
                    }}
                  >
                    Startup Market
                  </p>
                </div>
                {dataSidebar.map((value, index) => {
                  return (
                    value.display === true && (
                      <div
                        className={
                          activeSideBar === value.active
                            ? classes.activeLink
                            : classes.noActiveLink
                        }
                        onClick={() => history.push(value.path)}
                        key={index}
                      >
                        <p
                          className={
                            activeSideBar === value.active
                              ? classes.linkTextActive
                              : classes.linkTextUnActive
                          }
                        >
                          {value.name}
                        </p>
                      </div>
                    )
                  );
                })}
              </Stack>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {dataAuth.admin ? (
                  <PersonInfo />
                ) : (
                  <div
                    style={{
                      padding: "1px",
                      borderRadius: "25px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "25px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: theme.spacing(2, 3),
                        height: theme.spacing(5),
                        backgroundColor: "#00B6F0",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push("/login")}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "22px",
                          fontWeight: 700,
                          color: "#ffffff",
                          fontFamily: "Montserrat",
                          backgroundColor: "#00B6F0",
                        }}
                      >
                        {t("header.launch_app")}
                      </Typography>
                    </div>
                  </div>
                )}
              </Stack>
            </Stack>
          )}
          {isMobile.any() && window.innerWidth < 1440 && (
            <div className={classes.headerMobie}>
              <HeaderMobile dataSidebar={dataSidebar} />
            </div>
          )}
        </Toolbar>
        <div
          style={{
            height: "1px",
            width: "100%",
          }}
        ></div>
      </AppBar>
    </div>
  );
}
