import {
  Button,
  Drawer,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import startup_market from "../../assets/image/startup_market_logo_header.png";
import ReorderIcon from "@material-ui/icons/Reorder";
import background from "../../assets/image/background.png";
import backgroundLogin from "../../assets/image/background-header.png";
import { useTranslation } from "react-i18next";
import { PersonInfo } from "./PersonInfo";
import { AuthContext } from "containers/AuthProvider";

interface Props {
  dataSidebar: any[];
}

export const HeaderMobile: React.FC<Props> = ({ dataSidebar }) => {
  const dataAuth = useContext(AuthContext);
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ width: "100%", height: "100%" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 20,
        }}
      >
        <img
          src={startup_market}
          height={32}
          width={"auto"}
          style={{ cursor: "pointer" }}
          alt=""
          onClick={() => {
            history.push("/");
            (!dataAuth.admin?.country_id || !dataAuth.admin?.wallet_address) &&
              dataAuth.clear();
          }}
        />
        <p
          style={{
            fontSize: "16px",
            color: "#00B6F0",
            fontWeight: 700,
          }}
          onClick={() => {
            history.push("/");
            (!dataAuth.admin?.country_id || !dataAuth.admin?.wallet_address) &&
              dataAuth.clear();
          }}
        >
          Startup Market
        </p>
      </div>
      <Stack direction="row" alignItems="center">
        <React.Fragment key={"left"}>
          <Button onClick={() => setOpenDrawer(true)}>
            <IconButton>
              <ReorderIcon
                style={{
                  color: "black",
                  fontSize: "40px",
                  marginRight: "-20px",
                }}
              />
            </IconButton>
          </Button>
          <Drawer
            classes={{ paper: classes.backgroundDrawer }}
            anchor={"left"}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              spacing={2}
              style={{ height: "100%", padding: "10px 0px" }}
            >
              <div>
                {dataSidebar.map((value: any, index: number) => {
                  return (
                    value.display === true && (
                      <div
                        className={classes.containerDrawerItem}
                        onClick={() => {
                          history.push(value.path);
                          setOpenDrawer(false);
                        }}
                        key={index}
                      >
                        <p className={classes.drawerText}>{value.name}</p>
                      </div>
                    )
                  );
                })}
              </div>
              <div>
                {dataAuth.admin ? (
                  <PersonInfo />
                ) : (
                  <div
                    style={{
                      borderRadius: theme.spacing(1),
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: theme.spacing(2, 6),
                      height: theme.spacing(6),
                      backgroundColor: "#00B6F0",
                      cursor: "pointer",
                      margin: "8px",
                    }}
                    onClick={() => history.push("/login")}
                  >
                    <Typography
                      style={{
                        fontSize: "20px",
                        lineHeight: "24px",
                        fontWeight: 700,
                        color: "#FFFFFF",
                        textTransform: "uppercase",
                      }}
                    >
                      {t("header.launch_app")}
                    </Typography>
                  </div>
                )}
              </div>
            </Stack>
          </Drawer>
        </React.Fragment>
      </Stack>
    </Stack>
  );
};

const useStyles = makeStyles((theme) => ({
  backgroundDrawer: {
    backgroundColor: "#21243f80",
    minWidth: "240px",
  },
  containerDrawerItem: {
    width: "100%",
    height: "50px",
    paddingLeft: "10px",
  },
  drawerText: {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 900,
    color: "#FFFFFF",
    textTransform: "uppercase",
  },
  informationContainer: {
    borderTop: "1px solid #E7EBF0",
  },
  iconFlag: {
    margin: "10px",
    borderRadius: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    height: theme.spacing(6),
    backgroundImage: `url(${backgroundLogin}), url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundBlendMode: "color-dodge",
    border: "white solid 1px",
  },
  changeLanguageText: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    color: "white",
  },
}));
