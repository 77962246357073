import React, { useContext, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Stack, Typography, Checkbox } from "@mui/material";
import { AuthContext } from "containers/AuthProvider";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { useAPI } from "api/api";
import { useHistory } from "react-router-dom";
import { AutocompleteContainer } from "components/AutocompleteContainer";
import { useAccount, useConnect, chain } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import CancelIcon from "@material-ui/icons/Cancel";
import metamask from "../../assets/image/metamask.png";
type Data = {
  name: string;
  google_id: string;
  telegram_id: string;
  discord_id: string;
  email: string;
  country: any;
  wallet_address: string;
  facebook_id: string;
  facebook_channel: string;
  facebook_start_price: string;
  twitter_id: string;
  twitter_channel: string;
  twitter_start_price: string;
  youtube_channel: string;
  youtube_start_price: string;
  tiktok_channel: string;
  tiktok_start_price: string;
  my_referer_code: string;
};

type Field =
  | "name"
  | "google_id"
  | "telegram_id"
  | "discord_id"
  | "email"
  | "country"
  | "wallet_address"
  | "facebook_id"
  | "facebook_channel"
  | "facebook_start_price"
  | "twitter_id"
  | "twitter_channel"
  | "twitter_start_price"
  | "youtube_channel"
  | "youtube_start_price"
  | "tiktok_channel"
  | "tiktok_start_price"
  | "my_referer_code";

type SNSType = {
  sns_name: string;
  url: string;
  error?: boolean;
};

type dataSns = {
  sns_name: string;
  url: string;
};

const useStyle = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    background: "tranparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  contentRight: {
    padding: "40px 258px",
    backgroundColor: "#FFFFFFE5",
    borderRadius: "20px",
    width: 1050,
    minHeight: 1000,
    zIndex: 100,
  },
  input: {
    "&::placeholder": {
      fontStyle: "italic",
      color: "black",
    },
  },
  lineColor: {
    color: "black",
    borderRadius: "20px",
  },
  labelText: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    color: "black",
    marginBottom: "2px",
    marginTop: "24px",
  },
  textFieldContainer: {
    backgroundColor: "#0000004d",
    borderRadius: "20px",
    // border: "1px solid #bdbdbd4d",
    color: "black",
    marginBottom: "10px",
  },
}));

export default function Register() {
  const classes = useStyle();
  const dataAuth = useContext(AuthContext);
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const injected = new InjectedConnector({
    options: {
      shimDisconnect: false,
    },
  });
  const connector = new WalletConnectConnector({
    chains: [chain.mainnet, chain.optimism],
    options: {
      qrcode: true,
    },
  });
  const [connectorWallet, setConnectWallet] = useState<any>();
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  useEffect(() => {
    if (firstLoading === true) {
      localStorage.removeItem("walletconnect");
      localStorage.removeItem("wagmi.wallet");
      localStorage.removeItem("wagmi.connected");
      localStorage.removeItem("wagmi.cache");
      setFirstLoading(false);
    }
  }, [firstLoading]);

  const { connect } = useConnect({
    connector: connectorWallet,
  });

  const { address, isConnected } = useAccount();

  const [sns, setSns] = useState<SNSType[]>([]);

  const [checkSns, setCheckSns] = useState<boolean>(false);

  useEffect(() => {
    if (sns.length === 0) {
      setCheckSns(false);
    }
  }, [sns]);

  useEffect(() => {
    sns.forEach((value: SNSType) => {
      let check = false;
      if (value.sns_name === "" || value.url === "") {
        check = true;
      }
      setCheckSns(check);
    });
  }, [sns]);

  useEffect(() => {
    if (isConnected === true && address) {
      setData((pre: Data) => ({ ...pre, wallet_address: address as string }));
    }
  }, [address, isConnected]);

  const [data, setData] = useState<Data>({
    name: "",
    google_id: "",
    telegram_id: "",
    discord_id: "",
    email: "",
    country: {},
    wallet_address: "",
    facebook_id: "",
    facebook_channel: "",
    facebook_start_price: "",
    twitter_id: "",
    twitter_channel: "",
    twitter_start_price: "",
    youtube_channel: "",
    youtube_start_price: "",
    tiktok_channel: "",
    tiktok_start_price: "",
    my_referer_code: "",
  });

  useEffect(() => {
    if (dataAuth.admin) {
      const value: Data = {
        name: dataAuth.admin?.name,
        google_id: dataAuth.admin?.google_id,
        telegram_id: dataAuth.admin?.telegram_id,
        discord_id: dataAuth.admin?.discord_id,
        email: dataAuth.admin?.email,
        country: dataAuth.admin?.country,
        wallet_address: dataAuth.admin?.wallet_address,
        facebook_id: dataAuth.admin?.facebook_id,
        facebook_channel: dataAuth.admin?.facebook_channel,
        facebook_start_price:
          dataAuth.admin?.facebook_start_price != 0 &&
          dataAuth.admin?.facebook_start_price,
        twitter_id: dataAuth.admin?.twitter_id,
        twitter_channel: dataAuth.admin?.twitter_channel,
        twitter_start_price:
          dataAuth.admin?.twitter_start_price != 0 &&
          dataAuth.admin?.twitter_start_price,
        youtube_channel: dataAuth.admin?.youtube_channel,
        youtube_start_price:
          dataAuth.admin?.youtube_start_price != 0 &&
          dataAuth.admin?.youtube_start_price,
        tiktok_channel: dataAuth.admin?.tiktok_channel,
        tiktok_start_price:
          dataAuth.admin?.tiktok_start_price != 0 &&
          dataAuth.admin?.tiktok_start_price,
        my_referer_code: dataAuth.admin?.my_referer_code,
      };
      setData(value);
    }
  }, [dataAuth.admin]);

  const changeData = (value: string, field: Field) => {
    let tempData: Data = { ...data };
    tempData[field] = value;
    setData(tempData);
  };

  const api = useAPI();

  const history = useHistory();

  const [checkData, setCheckData] = useState<boolean>(false);

  const checkNameAndCountry = () => {
    let check = false;
    if (
      !data?.country?.id ||
      data?.name === null ||
      data?.wallet_address === null
    ) {
      check = true;
    }
    setCheckData(check);
    return check;
  };

  const updateData = async () => {
    const dataValidate = checkNameAndCountry();
    if (dataValidate === false && checkSns === false) {
      const dataSns: dataSns[] = [];
      sns.forEach((data: SNSType) => {
        dataSns.push({
          sns_name: data.sns_name,
          url: data.url,
        });
      });
      const res = await api.fetcher("put", "/user/update-me", {
        name: data.name ? data.name : null,
        google_id: data.google_id ? data.google_id : null,
        twitter_id: data.twitter_id ? data.twitter_id : null,
        twitter_channel: data.twitter_channel
          ? "https://twitter.com/" + data.twitter_channel
          : null,
        youtube_channel: data.youtube_channel
          ? "https://www.youtube.com/@" + data.youtube_channel
          : null,
        tiktok_channel: data.tiktok_channel
          ? "https://www.tiktok.com/@" + data.tiktok_channel
          : null,
        telegram_id: data.telegram_id ? data.telegram_id : null,
        discord_id: data.discord_id ? data.discord_id : null,
        email: data.email ? data.email : null,
        country_id: data.country ? data.country?.id : null,
        wallet_address: data.wallet_address ? data.wallet_address : null,
      });
      if (res) {
        let search = window.location.search.split("=");
        if (search[0] == "?url") {
          window.location.href = decodeURIComponent(search[1]);
        } else {
          history.go(0);
        }
      }
    }
  };

  const dataError: any = useMemo(() => {
    if (api.error) {
      return api.error;
    } else {
      return {};
    }
  }, [api.error]);

  const changeWallet = (value: string) => {
    if (value === "metamask") {
      setConnectWallet(injected);
    } else {
      setConnectWallet(connector);
    }
  };
  useEffect(() => {
    if (connectorWallet) {
      async function connectAddress() {
        try {
          await connect();
        } catch (error) {
          console.log(error);
        } finally {
          setOpen(false);
        }
      }
      // connect();
      if (connectorWallet) {
        connectAddress();
      }
    }
  }, [connectorWallet]);

  const [checkBox, setCheckBox] = useState(false);

  return (
    <div
      className={classes.container}
      style={{
        backgroundImage: "url(/registor-image.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding: "40px 0px",
      }}
    >
      <div className={classes.content}>
        <div className={classes.contentRight}>
          <Typography
            style={{
              marginTop: "28px",
              width: "100%",
              textAlign: "center",
              fontSize: "36px",
              fontWeight: 800,
              lineHeight: "43px",
              color: "black",
              marginBottom: "20px",
            }}
          >
            Your Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.labelText}>
                {t("home.Your_name")}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.textFieldContainer}
                placeholder={t("home.Your_name")}
                value={data?.name}
                onChange={(e) => changeData(e.target.value as string, "name")}
                InputProps={{
                  classes: { input: classes.input },
                  className: classes.lineColor,
                  style: { height: "50px", background: "white" },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelText}>Email</Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.textFieldContainer}
                placeholder="Email"
                value={data?.email}
                InputProps={{
                  classes: { input: classes.input },
                  className: classes.lineColor,
                  style: { height: "50px", background: "white" },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={
                checkData === true
                  ? { marginBottom: "10px" }
                  : { marginBottom: "0px" }
              }
            >
              <Typography className={classes.labelText}>
                Your Country *
              </Typography>
              <AutocompleteContainer
                label={t("header.country") + "*"}
                error={
                  checkData === true && !data?.country?.id
                    ? "Please select your country"
                    : ""
                }
                value={data?.country}
                endpoint="user/countries"
                queryField="name"
                labelField="name"
                valueField="id"
                handleChange={(e) => {
                  changeData(e as string, "country");
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                style={{
                  marginTop: "28px",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "36px",
                  fontWeight: 800,
                  lineHeight: "43px",
                  color: "black",
                  marginBottom: "20px",
                }}
              >
                {t("Update_info.social_media")}
              </Typography>
              <Typography className={classes.labelText}>Youtube</Typography>
              <Typography variant="subtitle2" color="#000000">
                {" "}
                https://www.youtube.com/
                <b style={{ color: "#56CCF2" }}>@name</b>
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.textFieldContainer}
                placeholder="Youtube Channel Name"
                value={data?.youtube_channel}
                InputProps={{
                  classes: { input: classes.input },
                  className: classes.lineColor,
                  style: { height: "50px", background: "white" },
                  startAdornment: (
                    <InputAdornment position="start" style={{ color: "black" }}>
                      <Typography style={{ color: "black" }}>@</Typography>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  changeData(e.target.value as string, "youtube_channel")
                }
                error={Boolean(dataError?.youtube_channel)}
              />
              {dataError?.youtube_channel && (
                <Typography
                  style={{
                    color: "#f44336",
                    fontSize: "14px",
                    fontWeight: 400,
                    marginLeft: "10px",
                  }}
                >
                  Youtube Channel {t("home.using")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelText}>X</Typography>
              <Typography variant="subtitle2" color="#000000">
                {" "}
                https://www.x.com/
                <b style={{ color: "#56CCF2" }}>name</b>
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.textFieldContainer}
                placeholder="Twitter Channel Name"
                value={data?.twitter_channel}
                InputProps={{
                  classes: { input: classes.input },
                  className: classes.lineColor,
                  style: { height: "50px", background: "white" },
                }}
                onChange={(e) =>
                  changeData(e.target.value as string, "twitter_channel")
                }
                error={Boolean(dataError?.twitter_channel)}
              />
              {dataError?.twitter_channel && (
                <Typography
                  style={{
                    color: "#f44336",
                    fontSize: "14px",
                    fontWeight: 400,
                    marginLeft: "10px",
                  }}
                >
                  Twitter Channel {t("home.using")}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.labelText}>Tiktok</Typography>
              <Typography variant="subtitle2" color="#000000">
                {" "}
                https://www.tiktok.com/
                <b style={{ color: "#56CCF2" }}>@name</b>
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.textFieldContainer}
                placeholder="Tiktok Channel Name"
                value={data?.tiktok_channel}
                InputProps={{
                  classes: { input: classes.input },
                  className: classes.lineColor,
                  style: { height: "50px", background: "white" },
                  startAdornment: (
                    <InputAdornment position="start" style={{ color: "black" }}>
                      <Typography style={{ color: "black" }}>@</Typography>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  changeData(e.target.value as string, "tiktok_channel")
                }
                error={Boolean(dataError?.tiktok_channel)}
              />
              {dataError?.tiktok_channel && (
                <Typography
                  style={{
                    color: "#f44336",
                    fontSize: "14px",
                    fontWeight: 400,
                    marginLeft: "10px",
                  }}
                >
                  Tiktok Channel {t("home.using")}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Typography
            style={{
              marginTop: "28px",
              width: "100%",
              textAlign: "center",
              fontSize: "36px",
              fontWeight: 800,
              lineHeight: "43px",
              color: "black",
              marginBottom: "20px",
            }}
          >
            Wallet
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!data?.wallet_address ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{
                  marginTop: "20px",
                  background: "rgba(255, 255, 255, 0.1)",
                  border:
                    checkData === true && !data?.wallet_address
                      ? "1px solid #f44336"
                      : "1px solid #bdbdbd4d",
                  padding: 0,
                  height: "50px",
                  borderRadius: "20px",
                  boxShadow: "unset",
                }}
                onClick={() => setOpen(true)}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "none",
                  }}
                >
                  <img
                    src="/metamask.png"
                    style={{
                      width: "27px",
                      height: "24px",
                      marginRight: "5px",
                    }}
                  />
                  {t("Update_info.connect_wallet")}
                </Typography>
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{
                  marginTop: "20px",
                  background: "rgba(255, 255, 255, 0.1)",
                  border: "1px solid #bdbdbd4d",
                  padding: 0,
                  height: "50px",
                  borderRadius: "20px",
                  boxShadow: "unset",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {data?.wallet_address}
                </Typography>
              </Button>
            )}
          </div>
          {checkData === true && !data?.wallet_address && (
            <Typography
              style={{ color: "#f44336", fontSize: "14px", fontWeight: 400 }}
            >
              {t("Update_info.need_connect")}
            </Typography>
          )}
          {"wallet_address" in api.error && (
            <>
              <Typography
                style={{
                  color: "#f44336",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                {t("Update_info.wallet_duplicate")}
              </Typography>
              <Typography
                style={{
                  color: "#f44336",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                {t("Update_info.change_wallet")}
              </Typography>
            </>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Checkbox
              checked={checkBox}
              onChange={() => {
                setCheckBox((e) => !e);
              }}
              color="primary"
              style={{ color: "black" }}
            />{" "}
            <p
              style={{
                color: "black",
                fontSize: "16px",
                fontWeight: 400,
                textDecoration: "none",
                lineHeight: "16px",
                cursor: "pointer",
                margin: "40px 0px",
              }}
            >
              agree to the terms and conditions
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{
                marginTop: "20px",
                backgroundColor: checkBox === false ? "gray" : "#56CCF2",
                padding: 0,
                height: "50px",
                borderRadius: "12px",
                boxShadow: "none",
              }}
              disabled={checkBox === false}
              onClick={() => updateData()}
            >
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#ffffff",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {t("Update_info.update")}
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: "#21243fe6",
            boxShadow: "none",
            width: "80%",
            borderRadius: "20px",
          },
        }}
        onBackdropClick={() => setOpen(false)}
      >
        <Stack
          style={{
            width: "100%",
            height: "300px",
            backgroundColor: "#21243f80",
            border: "1px solid white",
            borderRadius: "20px",
            padding: "24px",
          }}
          direction="column"
          alignItems="flex-end"
          spacing={2}
        >
          <IconButton
            style={{ color: "black", position: "absolute", top: 0, right: 0 }}
            onClick={() => setOpen(false)}
          >
            <CancelIcon />
          </IconButton>
          <Stack
            style={{ width: "100%", height: "100%" }}
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <div
              onClick={() => changeWallet("metamask")}
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <img
                src={metamask}
                width="150px"
                height="auto"
                alt="metamask_icon"
              />
              <Typography
                style={{ fontSize: "24px", fontWeight: 800, color: "white" }}
              >
                MetaMask
              </Typography>
            </div>
          </Stack>
        </Stack>
      </Dialog>
    </div>
  );
}
