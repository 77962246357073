import React, {
  useState,
  Suspense,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import AppLoading from "../../components/Loading";
import { AuthContext } from "../AuthProvider";
import { adminPrefix, AdminRoutes } from "../../routes/AdminRoutes";
import { isMobile, sidebardWidth } from "../../common/constants";
import Header from "./Header";
import Footer from "./Footer";
import UpdateInfo from "containers/Auth/UpdateInfo";
import FooterMobie from "./FooterMobie";
import { makeStyles } from "@material-ui/core";

type devoteeChildren = {
  label: string;
  path: string;
  perm: string;
};

const Layout = React.memo((props) => {
  const [open, setOpen] = useState(
    localStorage.getItem("sidebar")
      ? JSON.parse(localStorage.getItem("sidebar") as string)
      : false
  );
  useEffect(() => {
    localStorage.setItem("sidebar", open);
  }, [open]);
  const location = useLocation();
  const [path, setPath] = useState("");
  const { adminRoutes } = AdminRoutes();
  const classes = useStyle();
  const dataAuth = useContext(AuthContext);

  const prefix = useMemo(() => {
    if (dataAuth) {
      return adminPrefix;
    } else {
      return null;
    }
  }, [dataAuth]);

  const routes = useMemo(() => {
    if (dataAuth.admin) {
      if (!dataAuth.admin?.country_id || !dataAuth.admin?.wallet_address) {
        return [
          {
            path: "/",
            component: UpdateInfo,
            protected: true,
            perm: "",
            name: "Update Information",
          },
        ];
      } else {
        return adminRoutes;
      }
    } else {
      return adminRoutes.filter((data) => data.perm !== "admin");
    }
  }, [adminRoutes, dataAuth.admin]);
  const urlAdmin = [
    "project",
    "myPerformance",
    "project-snspromotion",
    "project-tokensale",
    "project-whitelist",
  ];
  const history = useHistory();
  useEffect(() => {
    if (location.pathname === prefix) setPath("/");
    let urlData = location.pathname.split("/");
    let search = window.location.search.split("=");
    if (
      !dataAuth.admin &&
      urlAdmin.includes(urlData[1]) &&
      search[0] != "?ref"
    ) {
      setPath("/");
      history.push("/login");
    }
  }, [location.pathname, path, prefix, dataAuth.admin, urlAdmin, history]);

  useEffect(() => {
    document.getElementById("layout")?.scroll(0, 0);
  }, [location]);

  function render(route: any) {
    return (
      <Route
        path={prefix + route.path}
        exact
        key={route.path}
        render={(_) => {
          setPath(route.path);
          return <route.component />;
        }}
      />
    );
  }

  const scrollEvent = () => {
    const scroll = document.querySelector("#layout") as Element;
    const data = document.getElementById("header");
    if (data) {
      if (
        document.body.scrollTop > 0 ||
        document.documentElement.scrollTop > 0 ||
        scroll?.scrollTop > 0
      ) {
        data.style.backgroundColor = "#fff";
      } else {
        data.style.backgroundColor = "#fff";
      }
    }

    var revealsTop = document.querySelectorAll(".reveal-top");
    var revealsleft = document.querySelectorAll(".reveal-left");
    var revealsRight = document.querySelectorAll(".reveal-right");
    var windowHeight = window.innerHeight;
    var elementVisible = 150;
    for (var i = 0; i < revealsTop.length; i++) {
      var elementTop1 = revealsTop[i].getBoundingClientRect().top;
      if (elementTop1 < windowHeight - elementVisible) {
        revealsTop[i].classList.add("active");
      } else {
        revealsTop[i].classList.remove("active");
      }
    }
    for (var i = 0; i < revealsleft.length; i++) {
      var elementTop2 = revealsleft[i].getBoundingClientRect().top;
      if (elementTop2 < windowHeight - elementVisible) {
        revealsleft[i].classList.add("active");
      } else {
        revealsleft[i].classList.remove("active");
      }
    }
    for (var i = 0; i < revealsRight.length; i++) {
      var elementTop3 = revealsRight[i].getBoundingClientRect().top;
      if (elementTop3 < windowHeight - elementVisible) {
        revealsRight[i].classList.add("active");
      } else {
        revealsRight[i].classList.remove("active");
      }
    }
  };

  useEffect(() => {
    if (location.pathname !== "/message") {
      dataAuth.updateConversation(0);
    }
  }, [dataAuth, location.pathname]);

  const [show, setShow] = useState<boolean>(true);

  return (
    <div>
      {isMobile.any() && window.innerWidth < 1440 ? (
        <div className={classes.rootMobile} onScroll={scrollEvent}>
          {show && <Header open={open} setOpen={setOpen} />}
          <main
            className={`${classes.content}`}
            style={{
              marginTop: isMobile.any() && window.innerWidth < 1440 ? 0 : 80,
              overflowX:
                isMobile.any() && window.innerWidth < 1440 ? "hidden" : "unset",
            }}
            id="layout"
          >
            <Suspense fallback={<AppLoading />}>
              <Switch>{routes.map((e) => render(e))}</Switch>
            </Suspense>
            {show && <FooterMobie />}
          </main>
        </div>
      ) : (
        <div
          className={classes.root}
          onScroll={scrollEvent}
          id="layout"
          style={{
            backgroundColor: "white",
          }}
        >
          {show && <Header open={open} setOpen={setOpen} />}
          <main className={`${classes.content}`} style={{ marginTop: 80 }}>
            <Suspense fallback={<AppLoading />}>
              <Switch>{routes.map((e) => render(e))}</Switch>
            </Suspense>
            {show && <Footer />}
          </main>
        </div>
      )}
    </div>
  );
});

const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: "100%",
    overflow: "auto",
    overflowX: "hidden",
    position: "fixed",
    color: "#474747",
  },
  rootMobile: {
    display: "flex",
    height: "100vh",
    overflow: "auto",
    position: "fixed",
  },
  background: {},
  toolbar: {
    height: 64,
  },
  content: {
    flexGrow: 1,
    paddingTop: "16px",
    position: "relative",
  },
  sidebarOpen: {
    maxWidth: `calc(100vw - ${sidebardWidth}px)`,
  },
  sidebarClose: {
    maxWidth: `calc(100vw - 70px)`,
  },
  toggleBtn: {
    padding: "2px 2px",
    cursor: "pointer",
    position: "fixed",
    zIndex: 100,
    background: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 30,
    width: 30,
    borderRadius: "50%",
    "&:hover": {
      background: "orange",
      "& svg": {
        color: "#fff",
      },
    },
  },
  toggleBtnOpen: {
    top: 89,
    left: 284,
  },
  toggleBtnClose: {
    top: 89,
    left: 45,
  },
}));
export default Layout;
