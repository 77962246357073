import React, { Suspense, useContext, useEffect, useState } from "react";
import { adminPrefix } from "./AdminRoutes";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "../containers/Layout/Layout";
import AppLoading from "../components/Loading";

import WhiteListForm from "containers/Admin/WhiteListForm/WhiteListForm";
import { MessageContext } from "containers/MessageProvider";
import { AuthContext } from "containers/AuthProvider";
import {
  getFromLocalStorage,
  saveToLocalStorage,
} from "containers/Admin/Chat/components/RoomList";
import TermOfUse from "containers/Layout/TermOfUse";
import { useFetch } from "api/api";

export default function MainRouter() {
  const {
    updateNotiMessage,
    updateDataFirebase,
    conversationSelected,
    admin,
  } = useContext(AuthContext);
  const {
    connWebsoket,
    updateNotiRoom,
    updateDataNotiSns,
    updateNewNotiNumber,
  } = useContext(MessageContext);
  const { data: dataNewNotification, revalidate } = useFetch(
    admin?.country_id && admin?.wallet_address
      ? ["get", "/user/new-notification"]
      : null
  );
  const {
    data: dataNewNotificationNumber,
    revalidate: revalidateNotificationNumber,
  } = useFetch(
    admin?.country_id && admin?.wallet_address
      ? ["get", "/user/new-notification-number"]
      : null
  );
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isFetching) {
        setIsFetching(true);
        revalidate().then(() => {
          setIsFetching(false);
        });
        revalidateNotificationNumber().then(() => {
          setIsFetching(false);
        });
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [revalidate, isFetching, revalidateNotificationNumber]);

  useEffect(() => {
    updateDataNotiSns(dataNewNotification);
    updateNewNotiNumber(dataNewNotificationNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNewNotification, dataNewNotificationNumber]);

  const unique = (arr: number[]) => {
    let newArr: number[] = [];
    newArr =
      arr &&
      arr.filter(function (item) {
        return newArr.includes(item) ? "" : newArr.push(item);
      });
    return newArr;
  };

  useEffect(() => {
    if (connWebsoket) {
      connWebsoket.onopen = () => {
        connWebsoket.send(
          JSON.stringify({
            command: "register",
          })
        );
        connWebsoket.send(
          JSON.stringify({
            command: "unread",
          })
        );
      };
      connWebsoket.onmessage = (e: any) => {
        const dataMessage = JSON.parse(e.data);
        if (
          dataMessage.command === "unread" &&
          dataMessage.data.rooms.length !== 0
        ) {
          updateNotiMessage(true);
          const listPoIdUnRead = dataMessage.data.rooms.map((item: any) => {
            return item.po_id;
          });
          if (listPoIdUnRead) {
            const storedArray = getFromLocalStorage("array");
            if (storedArray && storedArray.length !== 0) {
              const arr = storedArray.concat(listPoIdUnRead);
              saveToLocalStorage("listPoIdUnRead", unique(arr));
            } else saveToLocalStorage("listPoIdUnRead", listPoIdUnRead);
          }
        }
        if (dataMessage.command === "message") {
          updateNotiMessage(true);
          updateDataFirebase(dataMessage.data);
          if (
            dataMessage.data &&
            dataMessage.data.po_id !== +conversationSelected
          ) {
            let Po_id = [dataMessage.data.po_id];
            updateNotiRoom(Po_id);
          }
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <Router>
      <Suspense fallback={<AppLoading />}>
        <Switch>
          {/* <Route path={"/login"} exact strict component={Login} /> */}
          <Route
            path={"/whiteListForm"}
            exact
            strict
            component={WhiteListForm}
          />
          <Route path={"/term"} exact strict component={TermOfUse} />
          <Route path={adminPrefix} component={Layout} />
        </Switch>
      </Suspense>
    </Router>
  );
}
